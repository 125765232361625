export const SUPERADMIN = "SUPERADMIN"
export const ADMIN = "ADMIN"
export const CLIENT = "CLIENT"
export const SPECIALIST = "SPECIALIST"
export const USER_TYPES = [SUPERADMIN, ADMIN, CLIENT, SPECIALIST]


export const HOME = "HOME";
export const TASKS = "TASKS";


export const getPathNames = () => {
    return window.location.pathname.split("/");
};