export const pageVariants = {
	initial: {
		opacity: 0,
	},
	in: {
		opacity: 1,
	},
	out: {
		opacity: 0,
	},
}

export const pageTransition = {
	type: 'tween',
	ease: 'linear',
	duration: 0.5,
}

export const pageTransitionFullPage = {
	type: 'tween',
	ease: 'easeIn',
	duration: 0.8,
}
