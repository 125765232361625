import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { signIn } from "./redux/user/authSlice";
import { loginSuccess } from "./redux/superadmin/authSlice";
import RootRouter from "./routes/Router";
import FallbackLoader from './components/loaders/FallbackLoader'
import { SUPERADMIN } from "./helpers/router_helper";
import { jwtDecode } from "jwt-decode";

function App() {

  const [loaded, setLoaded] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.role || false);
  const isSuperadminAuthenticated = useSelector((state) => state.superadminAuth.role || false);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    const superadminToken = localStorage.getItem('superadminJwtToken');

    if (token) {
      const decodedToken = jwtDecode(token)
      dispatch(signIn({ role: decodedToken.user_type, token: token }));
    }

    if (superadminToken) {
      dispatch(loginSuccess({ role: SUPERADMIN, token: superadminToken }));
    }
    setLoaded(true)
  }, [dispatch]);

  return (
    <BrowserRouter>
      {loaded ? <>
        <RootRouter isAuthenticated={isAuthenticated} isSuperadminAuthenticated={isSuperadminAuthenticated} />
      </> : <FallbackLoader />}
    </BrowserRouter>
  );
}

export default App;
