import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  token: null,
  role: null,
  activeTasks: -1,
  unread: -1,
  profileImg: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn(state, action) {
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.role = action.payload.role;
    },
    signOut(state) {
      state.isAuthenticated = false;
      state.token = null;
      state.role = null;
    },
    updateActiveTasks(state, action) {
      state.activeTasks = action.payload.activeTasks
    },
    getUnreadNotifications(state, action) {
      state.unread = action.payload.unread
    },
    getProfileImage(state, action) {
      state.profileImg = action.payload.profileImg
    },
  },
});

export const { signIn, signOut, updateActiveTasks, getUnreadNotifications, getProfileImage } = authSlice.actions;
export default authSlice.reducer;
