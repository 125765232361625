import { configureStore } from '@reduxjs/toolkit';
import authReducer from './user/authSlice';
import superadminAuthReducer from './superadmin/authSlice'
import statusReducer from "./status/statusReducer";

const store = configureStore({
  reducer: {
    auth: authReducer,
    superadminAuth: superadminAuthReducer,
    status: statusReducer
  },
});

export default store;
