import { isEqual } from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const Wrapper = React.lazy(() => import("../components/wrapper/Layout"));

export const AuthRoute = ({ component: Component }) => {
  const location = useLocation();
  const auth = useSelector((state) => state.auth, isEqual);

  return !auth.isAuthenticated ? (
    <>
      <React.Suspense>
          <Component locationState={location.state} />
      </React.Suspense>
    </>
  ) : 
  (
    <Navigate
      to={{ pathname: '/dashboard' }}
      replace
      state={{ from: location }}
    />
  );
};

export const SuperadminAuthRoute = ({ component: Component }) => {
  const location = useLocation();
  const auth = useSelector((state) => state.superadminAuth, isEqual);

  return !auth.isAuthenticated ? (
    <>
      <React.Suspense>
          <Component locationState={location.state} />
      </React.Suspense>
    </>
  ) : 
  (
    <Navigate
      to={{ pathname: '/superadmin/dashboard' }}
      replace
      state={{ from: location }}
    />
  );
};

export const OpenRoute = ({ component: Component }) => {
  return (
    <>
      <React.Suspense>
        <Component />
      </React.Suspense>
    </>
  );
};

export const DashboardRoute = ({ component: Component }) => {
  const auth = useSelector((state) => state.auth, isEqual);
  const superadminAuth = useSelector((state) => state.superadminAuth, isEqual);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const sidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }
  return auth.isAuthenticated ? (
    <Wrapper sidebarToggle={sidebarToggle} isSidebarOpen={isSidebarOpen}>
      <>{Component}</>
    </Wrapper>
  ) : (
    (superadminAuth.isAuthenticated ? <Navigate replace to={{ pathname: "/superadmin/login" }} /> : <Navigate replace to={{ pathname: "/" }} />)
  );
};

export const SuperadminDashboardRoute = ({ component: Component }) => {
  const auth = useSelector((state) => state.superadminAuth, isEqual);
  const userAuth = useSelector((state) => state.superadminAuth, isEqual);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const sidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }
  return auth.isAuthenticated ? (
    <Wrapper sidebarToggle={sidebarToggle} isSidebarOpen={isSidebarOpen}>
      <>{Component}</>
    </Wrapper>
  ) : (
    (userAuth.isAuthenticated ? <Navigate replace to={{ pathname: "/login" }} /> : <Navigate replace to={{ pathname: "/" }} />)
  );
};