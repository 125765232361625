import React from "react";
import "../loaders/FallbackLoader.css";
import Logo from "../../assets/favicon.png";

const FallBackLoader = () => {
  return (
    <div className="loader-overlay">
      <div className="loader">
        <div className="spinner"></div>
        <img src={Logo} alt="Logo" className="logo" style={{ height: '90px', marginTop:'60px', marginLeft: '60px' }} />
      </div>
    </div>
  );
};

export default FallBackLoader;
