import { SET_STATUS_ONLINE, SET_STATUS_OFFLINE } from "./statusActionTypes";

const initialState = {
  isOnline: false,
};

const statusReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATUS_ONLINE:
      return {
        ...state,
        isOnline: true,
      };
    case SET_STATUS_OFFLINE:
      return {
        ...state,
        isOnline: false,
      };
    default:
      return state;
  }
};

export default statusReducer;
